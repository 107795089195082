module.exports = new Promise((resolve) => {
  function getRemoteUrl() {
    // Append a query param with a unique value to prevent caching
    const timestamp = Date.now();

    // QA / DEV / DEMO / PROD (EU|US)
    if (window.QIKWEBKIT_URL) {
      return `${window.QIKWEBKIT_URL}online-ordering/qikwebkit/remoteEntry.js?t=${timestamp}`;
    }

    // Default to QA
    return `${window.QIKWEBKIT_DEFAULT_URL}online-ordering/qikwebkit/remoteEntry.js?t=${timestamp}`;
  }

  const script = document.createElement('script');
  script.src = getRemoteUrl();

  // Success loading
  // The script is loaded on window using the name defined within the remote
  script.onload = () => {
    const module = {
      get: (request) => {
        return window.online_ordering_qikwebkit.get(request);
      },
      init: (arg) => {
        try {
          return window.online_ordering_qikwebkit.init(arg);
        } catch (error) {
          console.log('The remote "QikWebKit" has already been loaded.');
        }
      },
    };

    resolve(module);
  };

  // Error loading
  // We should resolve the promise to not crash application
  script.onerror = (error) => {
    console.error('Error loading "qikwebkit" remote', error);
    resolve();
  };

  document.head.appendChild(script);
});